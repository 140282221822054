import * as yup from "yup";
var passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
var usernameRules = /^[a-zA-Z0-9_-]{3,16}$/;
var emailValidation = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
var fullnameRules = /[A-Za-zА-Яа-яЁё]+(\s+[A-Za-zА-Яа-яЁё]+)?$/;
var phoneRules = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export var registrationSchemaForUser = yup.object().shape({
    // username: yup.string().required("Необходимое поле"),
    first_name: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Имя должно содержать только буквы английского или русского алфавита"),
    email: yup
        .string()
        .email("Пожалуйста введите корректный e-mail")
        .required("Необходимое поле")
        .matches(emailValidation, "Email должен содержать только буквы латинского алфавита точку или нижнее подчеркивание"),
    last_name: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Фамилия должно содержать только буквы английского или русского алфавита"),
    password: yup
        .string()
        .required("Необходимое поле")
        .min(8)
        .matches(passwordRules, { message: "см.Подсказку правил пароля справа" }),
    confirm_password: yup
        .string()
        .oneOf([yup.ref("password")], "Пароли не совпадают")
        .required("Повторите пароль правильно"),
});
export var registrationSchemaForExpert = yup.object().shape({
    first_name: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Имя должно содержать только буквы английского или русского алфавита"),
    last_name: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Фамилия должно содержать только буквы английского или русского алфавита"),
    email: yup
        .string()
        .email("Пожалуйста введите корректный e-mail")
        .required("Необходимое поле")
        .matches(emailValidation, "Email должен содержать только буквы латинского алфавита точку или нижнее подчеркивание"),
    special: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Должность должна содержать только буквы английского или русского алфавита"),
    work: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Раобота должна содержать только буквы английского или русского алфавита"),
    phone: yup.number().required("Необходимое поле"),
});
export var registrationSchemaForCompany = yup.object().shape({
    ceo: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Ceo должна содержать только буквы английского или русского алфавита"),
    email: yup
        .string()
        .email("Пожалуйста введите корректный e-mail")
        .required("Необходимое поле")
        .matches(emailValidation, "Email должен содержать только буквы латинского алфавита точку или нижнее подчеркивание"),
    name: yup
        .string()
        .max(30)
        .required("Введите полное имя вашей компании. Минимальная длина 5 символов")
        .matches(fullnameRules, "Имя компании должна содержать только буквы английского или русского алфавита"),
    phone: yup.number().required("Необходимое поле"),
});
export var authenticationSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .min(5)
        .required("Необходимое поле")
        .matches(emailValidation, "Email должен содержать только буквы латинского алфавита точку или нижнее подчеркивание"),
    password: yup.string().required("Необходимое поле"),
});
export var registrationDiscountSchema = yup.object().shape({
    who: yup.string().required("Необходимое поле"),
    email: yup
        .string()
        .email("Пожалуйста введите корректный e-mail")
        .required("Необходимое поле")
        .matches(emailValidation, "Email должен содержать только буквы латинского алфавита точку или нижнее подчеркивание"),
    name: yup.string().min(5).max(20).required("Введите полное имя вашей компании. Минимальная длина 5 символов"),
    phone: yup.number().required("Необходимое поле"),
});
